// Generated by Framer (3fa6aa4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ALGV0mGS_", "EG56WqO3k", "gppsnfUFC"];

const serializationHash = "framer-MKrAb"

const variantClassNames = {ALGV0mGS_: "framer-v-1iwvm6g", EG56WqO3k: "framer-v-sn3azr", gppsnfUFC: "framer-v-xxmpsl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Big: "EG56WqO3k", Medium: "ALGV0mGS_", Small: "gppsnfUFC"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, r4IsvrEYT: image ?? props.r4IsvrEYT ?? {src: "https://framerusercontent.com/images/2nWAA3krw8qkTjDMGMwF7IpDJo.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/2nWAA3krw8qkTjDMGMwF7IpDJo.png?scale-down-to=512 512w,https://framerusercontent.com/images/2nWAA3krw8qkTjDMGMwF7IpDJo.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/2nWAA3krw8qkTjDMGMwF7IpDJo.png 1920w"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ALGV0mGS_"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, r4IsvrEYT, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ALGV0mGS_", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1iwvm6g", className, classNames)} data-framer-name={"Medium"} layoutDependency={layoutDependency} layoutId={"ALGV0mGS_"} ref={ref ?? ref1} style={{backgroundColor: "rgba(255, 255, 255, 0.08)", borderBottomLeftRadius: 14, borderBottomRightRadius: 14, borderTopLeftRadius: 14, borderTopRightRadius: 14, ...style}} {...addPropertyOverrides({EG56WqO3k: {"data-framer-name": "Big"}, gppsnfUFC: {"data-framer-name": "Small"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", sizes: "319px", ...toResponsiveImage(r4IsvrEYT)}} className={"framer-66ztor"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"wM3WKKKUl"} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10}} {...addPropertyOverrides({EG56WqO3k: {background: {alt: "", fit: "fill", sizes: "360px", ...toResponsiveImage(r4IsvrEYT)}}, gppsnfUFC: {background: {alt: "", fit: "fill", sizes: "226px", ...toResponsiveImage(r4IsvrEYT)}}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MKrAb.framer-14xhr01, .framer-MKrAb .framer-14xhr01 { display: block; }", ".framer-MKrAb.framer-1iwvm6g { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 8px; position: relative; width: min-content; }", ".framer-MKrAb .framer-66ztor { aspect-ratio: 1.782122905027933 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 179px); position: relative; width: 319px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-MKrAb.framer-1iwvm6g { gap: 0px; } .framer-MKrAb.framer-1iwvm6g > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-MKrAb.framer-1iwvm6g > :first-child { margin-top: 0px; } .framer-MKrAb.framer-1iwvm6g > :last-child { margin-bottom: 0px; } }", ".framer-MKrAb.framer-v-sn3azr .framer-66ztor { height: var(--framer-aspect-ratio-supported, 202px); width: 360px; }", ".framer-MKrAb.framer-v-xxmpsl.framer-1iwvm6g { padding: 4px; }", ".framer-MKrAb.framer-v-xxmpsl .framer-66ztor { height: var(--framer-aspect-ratio-supported, 127px); width: 226px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 195
 * @framerIntrinsicWidth 335
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"EG56WqO3k":{"layout":["auto","auto"]},"gppsnfUFC":{"layout":["auto","auto"]}}}
 * @framerVariables {"r4IsvrEYT":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLs6wk8_Ch: React.ComponentType<Props> = withCSS(Component, css, "framer-MKrAb") as typeof Component;
export default FramerLs6wk8_Ch;

FramerLs6wk8_Ch.displayName = "Helper/ Image Container";

FramerLs6wk8_Ch.defaultProps = {height: 195, width: 335};

addPropertyControls(FramerLs6wk8_Ch, {variant: {options: ["ALGV0mGS_", "EG56WqO3k", "gppsnfUFC"], optionTitles: ["Medium", "Big", "Small"], title: "Variant", type: ControlType.Enum}, r4IsvrEYT: {__defaultAssetReference: "data:framer/asset-reference,2nWAA3krw8qkTjDMGMwF7IpDJo.png?originalFilename=Rectangle+543468.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerLs6wk8_Ch, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})